import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
// slider imports
import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//

const ReviewsSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      <div className="single-slide-content">
        <p>
          We've been taking our bikes to Jp Superbikes since we got them and they do a great job. They always go the
          extra mile to get the best quality part at the best price. Whenever we call for help with our{' '}
        </p>
      </div>
      <div className="single-slide-content">
        <p className="lead">
          I have been a customer of JP Superbikes for three years. They are great. Their work is first rate and they are
          always honest and reasonably priced. I always feel like I get good value and helpful advice as well. This is
          the <strong>honest mechanic</strong> you have been looking for.
        </p>
      </div>
      <div className="single-slide-content">
        <p className="lead">
          JP Superbikes just finished installing upgrades to my new <strong>Honda F6B Goldwing</strong>, the work was
          neat, professional, on time and reliable. This is my third bike I have taken to JP for all kinds of{' '}
          <strong>services, installation of upgrades, etc.</strong>, over 9 years dealing exclusively with them, and
          after many <strong>new tires, oil and filters, brake jobs, new installs, consultations</strong> on what the
          next bike should be, you name it, Jon at JP is always gracious with his time, and most important, always gets
          the job done right the first time. Highly recommend JP to anyone needing any kind of work on their bikes, you
          won't regret it.
        </p>
      </div>
      <div className="single-slide-content">
        <p className="lead">
          I have been taking my bikes to Jon since 2008 when his shop was half the size.{' '}
          <strong>No other shop offers better deals in tires</strong> (changed at least 10 since then - I do ride a
          lot!) and overall full service. Their work is professional, clean and affordable. I wouldn't trust anyone else
          with my <strong>valve adjustments, carburetors or fuel injectors... </strong> If you think you found a good
          deal on tires or parts on ebay, try again at JP Superbikes! It is the most honest and reliable{' '}
          <strong>motorcycle shop in SW Florida period!</strong>{' '}
        </p>
      </div>
      <div className="single-slide-content">
        <p className="lead">
          By far<strong>the best bike shop in south west Florida.</strong> I took my 06' Hayabusa to John and he set me
          up with some{' '}
          <strong>new tires, new brake pads, rear rotor and an oil change for an extremely reasonable price.</strong> I
          looked at tire prices online and his prices were just as good if not better and he had them in stock. After i
          got home I noticed he even topped my bike off with some new coolant. Now that's Service!{' '}
        </p>
      </div>
    </Slider>
  );
};

export default ReviewsSlider;
