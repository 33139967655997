import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
//
import './index.scss';

const RepairShopParalax = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "repair-shop-paralax.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  `);

  const style = { '--bg-repair-shop': `url("${data.file.childImageSharp.fluid.srcWebp}")` };

  return <section className="repair-shop-paralax" style={style}></section>;
};

export default RepairShopParalax;
