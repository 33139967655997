/* eslint-disable */

import React from 'react';
import EbikeCard from '../EbikeCard';
import BikeCard from '../BikeCard';
//
import './index.scss';
import { graphql, useStaticQuery } from 'gatsby';

const SectionTitle = ({ text }) => (
  <h2 className="text-black" style={{ fontWeight: 600 }}>
    {text}
  </h2>
);

const useFixedLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "jpsstore-logo" } }) {
        edges {
          node {
            name
            image: childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const logo = data?.allFile?.edges?.[0]?.node || null;
  const logoFixedImage = logo?.image?.fixed || null;

  return logoFixedImage;
};

export const Motorcycles = props => {
  const { availableMotorcycles } = props;
  const logoFixedImage = useFixedLogo();

  return (
    <div className="home-motorcycles">
      <div className="container">
        <SectionTitle text="Certified used motorcycles, scooters, ATVs/UTVs in SW Florida." />
        <hr className="two-line-divider" />
        <div className="motorcycles-grid">
          {availableMotorcycles.map(motorcycle => {
            return (
              <BikeCard
                key={motorcycle.node.id}
                featuredImage={motorcycle.node.featuredImage.image.asset.fluid}
                title={motorcycle.node.title}
                year={motorcycle.node.year}
                price={motorcycle.node.price}
                mileage={motorcycle.node.mileage}
                engine={motorcycle.node.engine}
                slug={motorcycle.node.slug.current}
                logoFixedImage={logoFixedImage}
                hasLogo={motorcycle.node.hasLogo}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const EBikes = props => {
  const { availableEbikes } = props;
  const logoFixedImage = useFixedLogo();

  return (
    <div className="home-motorcycles">
      <div className="container">
        <SectionTitle text="Shop certified E-Bikes" />
        <hr className="two-line-divider" />
        <div className="motorcycles-grid">
          {availableEbikes.map(eBike => {
            return (
              <EbikeCard
                key={eBike.node.id}
                {...eBike.node}
                featuredImage={eBike.node.featuredImage.image.asset.fluid}
                slug={eBike.node.slug.current}
                logoFixedImage={logoFixedImage}
                hasLogo={eBike.node.hasLogo}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
