import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
//

import ReviewsSlider from '../Sliders/ReviewsSlider/index';

import './_index.scss';

const CustomerReviews = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bg-customer-reviews.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  `);

  const style = { '--bg-customer-reviews': `url("${data.file.childImageSharp.fluid.srcWebp}")` };

  return (
    <div className="customer-reviews" style={style}>
      <div className="custom-container">
        <div className="reviews-wrapper">
          <div>
            <h3 className="text-black">Thousands of Customers Behind Our Success. </h3>
            <hr className="hr-reviews" />
            <p className="text-gray">
              JP Superbikes is formed on the idea that there is a better way to <strong>purhcase a motorcycle</strong>{' '}
              and keep it running. Our goal is to make it easy and fun for our customers so that they have the best
              shopping experience possible.
            </p>
            <Link to="/about" className="text-red uppercase link-know-more">
              Know More
            </Link>
          </div>
          <div className="reviews-slider">
            <ReviewsSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
