import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
//
import './index.scss';

const PartsDepartment = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "Parts-department" } }, sort: { fields: size }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="parts-department">
      <div className="container">
        <h2 className="offers text-black text-center">Parts Department</h2>
        <h3 className="text-black text-center">
          <strong>OEM and aftermarket parts</strong> for all makes and models. Call or come by our store to shop with
          our knowledgeable parts sales specialists.{' '}
        </h3>
        <hr className="hr-reviews text-center" />
        <div className="logo-container">
          <Img
            fluid={{ ...data.allFile.edges[0].node.childImageSharp.fluid, aspectRatio: 2.9 }}
            imgStyle={{ objectFit: 'contain' }}
            alt="Wps logo image"
          />
          <Img
            fluid={{ ...data.allFile.edges[1].node.childImageSharp.fluid, aspectRatio: 2.9 }}
            alt="trucker logo image"
          />
          <Img
            fluid={{ ...data.allFile.edges[2].node.childImageSharp.fluid, aspectRatio: 2.9 }}
            alt="Sulivans logo image"
            imgStyle={{ objectFit: 'contain' }}
          />
          <Img
            fluid={{ ...data.allFile.edges[3].node.childImageSharp.fluid, aspectRatio: 2.9 }}
            alt="parts unlimited logo image"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <h2 className="text-black text-center">Why Choose JP Superbikes Superstore?</h2>
        <hr className="hr-reviews text-center" />
        <div className="why-choose-jp">
          <div className="reputation">
            <h4 className="text-black">Great reputation </h4>
            <p className="text-black subtitle">Professionally trained technicians </p>
            <p className="text-black">
              Our dealership has a rock-solid reputation of providing excellent <strong>motorcycle service</strong>,
              support, and satisfaction. At JP Superbikes, we pride ourselves on giving our customers the best service.
            </p>
          </div>
          <div className="service">
            <h4 className="text-black">The best service</h4>
            <p className="subtitle">Up front, low prices and no haggling</p>
            <p>
              JP Superbikes Superstore carries a variety of <strong>parts and accessories</strong>. We have earned a
              great repuation with our customer serverice and experience.
            </p>
          </div>
          <div className="relationships">
            <h4 className="text-black">Relationships</h4>
            <p className="text-black subtitle">Caring attitude toward customers</p>
            <p className="text-black">
              Purchasing locally allows you to establish a relationship with your dealer representative providing you
              with the convenience of service and support when you need it.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartsDepartment;
