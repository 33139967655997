/* eslint-disable */

import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { useStaticQuery, graphql, Link } from 'gatsby';

// Import css files for slick slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

export const EbikesSlide = ({ allImageNodes }) => {
  const eBikeImageNode = allImageNodes.find(node => node.name === 'e-bike-slide');

  return (
    <div>
      <Img
        className="slide-bg-image"
        fluid={{ ...eBikeImageNode.childImageSharp.fluid, aspectRatio: 2 }}
        alt="first slider image"
      />
      <div className="slide-content e-bike-slide-caption">
        <p className="captionbg">
          A certified selection of electric bikes for your style, budget, and transportation needs.
        </p>
        <Link to="/e-bikes" className="link-main contact-us uppercase">
          ALL E-BIKES
        </Link>
      </div>
    </div>
  );
};

const HomeSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { ne: "e-bike-slide" }, relativeDirectory: { eq: "Home-slider" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      eBikeFiles: allFile(filter: { name: { eq: "e-bike-slide" }, relativeDirectory: { eq: "Home-slider" } }) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const SampleNextArrow = props => {
    const { className, onClick } = props;
    return <div role="button" className={className} onClick={onClick} />;
  };

  const SamplePrevArrow = props => {
    const { className, onClick } = props;
    return <div role="button" className={className} onClick={onClick} />;
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    speed: 1400,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="home-slider">
      <Slider {...settings}>
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[1].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="first slider image"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg ">
              Motorcycles, Scooters, ATV/UTV <br /> Sales, Service, Parts, Apparels.
            </p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
        <EbikesSlide allImageNodes={data.eBikeFiles.edges.map(({ node }) => node)} />
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[2].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="first slide caption"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg ">JP Superbikes LLC has over 20 years of experience!</p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[3].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="third slide caption"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg ">A great selection of certified used motorcycles, scooters and ATVs/UTVs.</p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[4].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="fourth slide caption"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg ">Anything you need for your ride, we can help you!</p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[5].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="fourth slide caption"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg ">Accident? We provide quick, professional repair.</p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
        <div>
          <Img
            className="slide-bg-image"
            fluid={{ ...data.allFile.edges[6].node.childImageSharp.fluid, aspectRatio: 2 }}
            alt="fourth slide caption"
          />
          <div className="slide-content">
            <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid} alt="first slide caption" />
            <p className="captionbg">
              Easy Financing Available!
              <br />
              Consignment and trades are welcome!
            </p>
            <Link to="/contact" className="link-main contact-us uppercase">
              contact us
            </Link>
            <Link to="/services" className="link-main our-services uppercase">
              our services
            </Link>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default HomeSlider;
